import { ZUimages } from "assets";
import { UserImgData } from "utils/userImg";
import { Link } from "react-router-dom";
import UserDateTimeDisplay from "./UserDateTimeDisplay";
import { meetingsList } from "utils";
import { ClockIcon, moreHorizontalIcon } from "assets/icons";

const ActionsList = () => {
  return (
    <div className="w-full">
      <div className="lg:max-w-[625px]">
        <UserDateTimeDisplay />
      </div>
      <div className="lg:overflow-scroll lg:h-[56vh]">
        {meetingsList.map((meeting) => (
          <div
            key={meeting.id}
            className="max-h-full md:h-[194px] mb-4 rounded-2xl p-8 bg-green-100 lg:max-w-[625px]"
          >
            <div className="flex flex-col h-full justify-between">
              <div className="flex items-center justify-between w-ful mb-5 md:mb-0">
                <div className="flex flex-col gap-2">
                  <p className="font-bold text-[21px] text-black">{meeting.title}</p>
                  <div className="flex gap-2 items-center">
                    <span>
                      <img src={ClockIcon} />
                    </span>
                    <p className="text-gray-200 text-base">
                      {meeting.startTime} - {meeting.endTime}
                    </p>
                    <p className="text-gray-200 text-base">|</p>
                    <p
                      className={`${
                        meeting?.meetingStatus.includes("has started")
                          ? "text-[#FF0000]"
                          : "text-gray-200"
                      } text-base`}
                    >
                      {meeting.meetingStatus}
                    </p>
                  </div>
                </div>
                <label className="flex items-center justify-center h-[32px] w-[43px] bg-gradient-to-r from-[#daf0e1] to-[#cdebd7] rounded-lg">
                  <img
                    src={moreHorizontalIcon}
                    alt=""
                    className="h-[4px] w-[20.01px] object-contain"
                  />
                </label>
              </div>
              <div className="flex justify-between max-w-[480px] flex-wrap">
                <div className="flex mb-5 md:mb-0">
                  {UserImgData.length <= 4
                    ? UserImgData.map((item) => (
                        <img
                          key={item.id}
                          src={item.userImgUrl}
                          className="rounded-full h-10 w-10 -mr-2"
                        />
                      ))
                    : UserImgData.slice(0, 4).map((item) => (
                        <img
                          key={item.id}
                          src={item.userImgUrl}
                          className="rounded-full h-10 w-10 -mr-2"
                        />
                      ))}
                  {UserImgData.length > 4 && (
                    <div className="bg-green rounded-full h-10 w-10 flex items-center justify-center text-white">
                      +{UserImgData.length - 4}
                    </div>
                  )}
                </div>
                <div className="flex flex-col justify-between">
                  {/* <img /> */}
                  <Link
                    to=""
                    className="font-normal text-[18px] bg-green rounded-lg py-2 px-4 text-white flex gap-2 items-center justify-center"
                  >
                    <span>
                      <img src={ZUimages.ZuPlay} />
                    </span>{" "}
                    Join
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ActionsList;
