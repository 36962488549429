import { Link } from "react-router-dom";
import { motion, useCycle, AnimatePresence } from "framer-motion";

import NavLink from "./NavLink";
import { ZUimages } from "assets";
import { Drawer } from "components/modules";
import { logout } from "redux/slices/auth.slice";
import { Button, Dropdown, Menu } from "components/widgets";
import { useAppDispatch, useAppSelector, useScroll } from "hooks";
import { ZuFlagUnitedKingdom, ZuDown, ZuLogout, ZuSettings, ZuUser } from "components/icons";
import {
  ABOUT,
  CONTACT,
  HOME,
  LOGIN,
  SIGNUP,
  PRICING,
  PRODUCTS,
  SETTINGS,
  DASHBOARD
} from "routes/CONSTANTS";
// import { Products } from "pages";

interface Props {
  transparent?: boolean;
}

const Navbar = ({ transparent = false }: Props) => {
  const { y } = useScroll();
  const dispatch = useAppDispatch();
  const { user, isLoggedIn } = useAppSelector((state) => state.auth);

  const [open, toggle] = useCycle(false, true);

  const handleLogout = () => {
    void dispatch(logout());
  };

  return (
    <motion.div
      initial={false}
      animate={open ? "open" : "closed"}
      className={`${
        transparent && y <= 40 ? "bg-transparent" : "bg-black-100"
      } fixed lg:relative top-0 left-0 right-0 text-white z-50`}
    >
      <div className="relative w-full h-[10vh] px-5 md:px-10 xl:px-20 flex items-center justify-between z-50">
        <Link to={HOME}>
          <img src={ZUimages.logo} alt="logo" />
        </Link>

        <div className="hidden lg:inline-flex items-center space-x-3">
          <NavLink to="/">Home</NavLink>
          <Dropdown
            button={
              <div className="flex items-center gap-2 cursor-pointer">
                <NavLink to={PRODUCTS}>Products</NavLink>
                <ZuDown size={10} />
              </div>
            }
          >
            <p></p>
          </Dropdown>
          <NavLink to={CONTACT}>Contact Us</NavLink>
          <NavLink to={PRICING}>Pricing</NavLink>
          <NavLink to={ABOUT}>About Us</NavLink>
        </div>

        <div className="inline-flex items-center space-x-3">
          {!isLoggedIn ? (
            <div className="hidden lg:inline-flex items-center space-x-3">
              <Button to={LOGIN} variant="outline">
                Sign In
              </Button>
              <Button to={SIGNUP}>Create an Account</Button>
            </div>
          ) : (
            <div className="inline-flex items-center space-x-3">
              <p className="hidden lg:block">Welcome {user.user.name.firstName}</p>
              <Dropdown
                button={
                  <Link to={DASHBOARD}>
                    <div className="w-10 h-10 rounded-full overflow-hidden cursor-pointer">
                      <img src={ZUimages.user1} className="w-full h-full object-cover" />
                    </div>
                  </Link>
                }
              >
                <>
                  <div className="w-60 flex flex-col items-center p-2">
                    <div className="flex flex-col items-center mb-8 mt-4">
                      <div className="w-14 h-14 rounded-full overflow-hidden cursor-pointer">
                        <img src={ZUimages.user1} className="w-full h-full object-cover" />
                      </div>
                      <p className="text-black font-semibold text-[1.2rem]">
                        {user.user.name.firstName} {user.user.name.lastName}
                      </p>
                    </div>
                    <div className="w-full">
                      <div className="pb-4 flex flex-col border-b">
                        <Link
                          to={DASHBOARD}
                          className="flex items-center gap-3 cursor-pointer hover:bg-slate-100 p-2 rounded-lg"
                        >
                          <ZuUser color="black" size={15} />
                          <p className="text-black text-[1rem]">My Profile Dashboard</p>
                        </Link>
                        <Link
                          to={SETTINGS}
                          className="flex items-center gap-3 cursor-pointer hover:bg-slate-100 p-2 rounded-lg"
                        >
                          <ZuSettings color="black" size={15} />
                          <p className="text-black text-[1rem]">Settings</p>
                        </Link>
                      </div>
                      <button
                        onClick={handleLogout}
                        className="flex items-center w-full gap-3 mt-4 hover:bg-red-500/20 p-2 rounded-lg"
                      >
                        <ZuLogout className="text-black" />
                        <p className="text-[1rem] text-black">Logout</p>
                      </button>
                    </div>
                  </div>
                </>
              </Dropdown>
            </div>
          )}
          <Dropdown
            button={
              <div className="flex items-center gap-2 px-1 py-px border-2  b border-white rounded-lg cursor-pointer">
                <ZuFlagUnitedKingdom size={20} />
                <ZuDown size={10} />
              </div>
            }
          >
            <></>
          </Dropdown>
          <div className="flex items-center lg:hidden">
            <Menu toggle={toggle} />
          </div>
        </div>
      </div>
      <AnimatePresence>{open && <Drawer open={open} />}</AnimatePresence>
    </motion.div>
  );
};

export default Navbar;
