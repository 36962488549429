import { ZUimages } from "assets";
import { SIGNUP } from "routes/CONSTANTS";
import { Link } from "react-router-dom";
const Hero = () => {
  return (
    <div
      className="w-full md:h-screen h-[50vh] bg-black-100"
      style={{ backgroundImage: `url('${ZUimages.heroBackground}')` }}
    >
      <div className="bg-black-100 bg-opacity-50 absolute inset-0 flex items-center justify-between md:h-screen h-[50vh] overflow-hidden w-full ">
        <div className=" flex flex-col md:gap-12 gap-4 text-white md:pl-20 pl-5  md:w-[711px] w-[284px] pt-8 ">
          <div className="md:leading-[100px] leading-8">
            <p className="md:leading-[60px] min-[320px]:text-[20px] min-[320px]:font-extrabold min-[320px]:leading-[30px] min-[280px]:mt-14 text-[28px] font-bold md:text-[40px] text-white font-normal">
              Africa’s top AI-powered virtual meeting platform
              {/* <span className="font-black text-[#50C878]"> Powerfully.</span> */}
            </p>
          </div>

          <p className=" md:text-[28px] text-lg">
            Speak your native language, connect and translate with other Africans across the globe -
            Zumaridi's advanced AI transaltes multiple African languages in real time.
          </p>
          <div className="md:w-full ">
            <Link to={SIGNUP}>
              <div className="min-[320px]:mb-10 w-[85%] bg-[#50C878] hover:bg-green md:py-4 py-2 px-4 flex items-center justify-center rounded-md">
                GET STARTED!
              </div>
            </Link>
          </div>
        </div>

        <div className="md:mt-60">
          <img
            src={ZUimages.youngManWithVector}
            className=" md:h-full h-[30vh] md:w-full w-[100px]"
          />
        </div>
      </div>
    </div>
  );
};
export default Hero;
