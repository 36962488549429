export const othersData = [
  { id: 1, title: "Show my connection time" },
  { id: 2, title: "Auto-Save recordings" },
  { id: 3, title: "Informs you when someone leaves the meeting" },
  { id: 4, title: "Dark Theme" },
  { id: 5, title: "Switch off my video when joining" },
  { id: 6, title: "Mute my microphone when joining" },
  { id: 7, title: "Show non-video participants" },
  { id: 8, title: "Use keyboard shortcuts" },
  { id: 9, title: "Informs you when someone join the meeting" }
];

export const othersData2 = [
  { id: 1, title: "About" },
  { id: 2, title: "Help and Support" },
  { id: 3, title: "Privacy and security" }
];
