import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import env from "configs";
import { NEW_MEETING_TAG } from "./CONSTANTS";
import type { RootState } from "redux/store";

export const api = createApi({
  reducerPath: "meetingApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${env.API_BASE_URL}`,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = (getState() as RootState).auth?.user?.accessToken;
      if (accessToken) {
        headers.set("authorization", `Bearer ${accessToken}`);
      }
      return headers;
    }
  }),
  tagTypes: [NEW_MEETING_TAG],
  endpoints: () => ({})
});
