import { SVGProps } from "react";
/* eslint-disable prettier/prettier */
type Props = {
  size?: number,
  color?: string,
  className?: string
} & SVGProps<SVGSVGElement>;
const SvgZuRoundSnapchat = ({ size, color, className, ...props }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 76 76"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path fill="#CDCDCD" d="M0 0h76v76H0z" />
      <path
        d="M-8025-2636c0-1.1.9-2 2-2H4043c1.1 0 2 .9 2 2v9744c0 1.1-.9 2-2 2H-8023c-1.1 0-2-.9-2-2v-9744Z"
        fill="#404040"
      />
      <path fill="#F7F7F7" d="M-429-570h1440V465H-429z" />
      <rect x={-141} y={-15} width={1054} height={106} rx={8} fill="#fff" />
      <circle cx={38} cy={38} r={38} fill="#FFFC00" />
      <mask id="zu_round_snapchat_svg__a" fill="#fff">
        <path d="M28.23 26.177c-.95 2.128-.575 5.94-.423 8.607-1.026.569-2.344-.429-3.09-.429-.775 0-1.701.51-1.847 1.27-.105.548.14 1.346 1.901 2.04.681.27 2.301.587 2.676 1.47.527 1.242-2.707 6.972-7.787 7.808a.788.788 0 0 0-.658.821c.088 1.544 3.55 2.149 5.084 2.386.157.213.283 1.109.484 1.791.09.306.323.672.922.672.78 0 2.077-.602 4.335-.228 2.213.369 4.294 3.507 8.289 3.507 3.713 0 5.928-3.153 8.059-3.507 1.233-.205 2.293-.14 3.477.091.815.16 1.547.249 1.78-.552.204-.692.329-1.57.482-1.778 1.52-.236 4.997-.84 5.084-2.383a.788.788 0 0 0-.658-.822c-4.994-.823-8.327-6.536-7.787-7.807.374-.882 1.982-1.196 2.676-1.47 1.289-.508 1.935-1.133 1.92-1.857-.017-.926-1.132-1.479-1.952-1.479-.834 0-2.033.988-3.003.453.152-2.688.525-6.484-.423-8.61-1.797-4.026-5.795-6.063-9.791-6.063-3.971 0-7.94 2.008-9.75 6.07Z" />
      </mask>
      <path
        d="M28.23 26.177c-.95 2.128-.575 5.94-.423 8.607-1.026.569-2.344-.429-3.09-.429-.775 0-1.701.51-1.847 1.27-.105.548.14 1.346 1.901 2.04.681.27 2.301.587 2.676 1.47.527 1.242-2.707 6.972-7.787 7.808a.788.788 0 0 0-.658.821c.088 1.544 3.55 2.149 5.084 2.386.157.213.283 1.109.484 1.791.09.306.323.672.922.672.78 0 2.077-.602 4.335-.228 2.213.369 4.294 3.507 8.289 3.507 3.713 0 5.928-3.153 8.059-3.507 1.233-.205 2.293-.14 3.477.091.815.16 1.547.249 1.78-.552.204-.692.329-1.57.482-1.778 1.52-.236 4.997-.84 5.084-2.383a.788.788 0 0 0-.658-.822c-4.994-.823-8.327-6.536-7.787-7.807.374-.882 1.982-1.196 2.676-1.47 1.289-.508 1.935-1.133 1.92-1.857-.017-.926-1.132-1.479-1.952-1.479-.834 0-2.033.988-3.003.453.152-2.688.525-6.484-.423-8.61-1.797-4.026-5.795-6.063-9.791-6.063-3.971 0-7.94 2.008-9.75 6.07Z"
        fill="#fff"
        stroke="#000"
        strokeWidth={2}
        mask="url(#zu_round_snapchat_svg__a)"
      />
      <path
        d="M-8023-2637H4043v-2H-8023v2Zm12067 1v9744h2v-9744h-2Zm-1 9745H-8023v2H4043v-2Zm-12067-1v-9744h-2v9744h2Zm1 1c-.55 0-1-.45-1-1h-2c0 1.66 1.34 3 3 3v-2Zm12067-1c0 .55-.45 1-1 1v2c1.66 0 3-1.34 3-3h-2Zm-1-9745c.55 0 1 .45 1 1h2c0-1.66-1.34-3-3-3v2Zm-12066-2c-1.66 0-3 1.34-3 3h2c0-.55.45-1 1-1v-2Z"
        fill="#fff"
        fillOpacity={0.1}
      />
    </svg>
  );
};
SvgZuRoundSnapchat.defaultProps = {
  size: 16,
  color: "currentColor"
};
export default SvgZuRoundSnapchat;
