import { othersData, othersData2 } from "utils";

const OthersView = () => {
  return (
    <div>
      {othersData.map((other) => (
        <div
          key={other.id}
          className="flex py-2.5 px-2 mb-6 w-full text-black rounded-md h-[54px] border-2 border-green-600 bg-white"
        >
          <p className="w-full overflow-hidden justify-center items-center mr-2">{other.title}</p>
          <label className="relative inline-flex items-center mb-5 cursor-pointer">
            <input type="checkbox" value="" className="sr-only peer" />
            <div className="w-11 h-6 mt-3 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
          </label>
        </div>
      ))}
      {othersData2.map((other2) => (
        <div
          key={other2.id}
          className="flex py-2.5 px-2 mb-6 w-full text-gray-900 rounded-md h-[54px] border-2 border-green-600 bg-white"
        >
          <p className="w-full">{other2.title}</p>
          <svg
            data-accordion-icon
            className="w-6 h-6 rotate-180 shrink-0"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </div>
      ))}
      <div className="flex py-2.5 px-2 mb-6 w-full text-gray-900 rounded-md h-[54px] border-2 border-green-600 bg-white">
        <p className="w-full overflow-hidden">Subtitle text language</p>
        <select
          id="small"
          className="flex p-2 text-sm text-green-600 bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-green"
        >
          <option value="US" selected>
            United States
          </option>
          <option value="CA">Canada</option>
          <option value="FR">France</option>
          <option value="DE">Germany</option>
        </select>
      </div>
    </div>
  );
};

export default OthersView;
