import { Button, Input, Loader } from "components";
import { user } from "assets/images";
import { ZuEdit } from "components/icons";
import { FormikProps } from "formik";
interface Props {
  loading: boolean;
  formik: FormikProps<{
    fullName: string;
    email: string;
    profession: string;
    language: string;
    phoneNumber: string;
    address: string;
    city: string;
    zip: string;
    state: string;
    country: string;
    dateOfBirth: string;
  }>;
}

function ProfileView({ loading, formik }: Props) {
  return (
    <div className="flex flex-col">
      <form onSubmit={formik.handleSubmit}>
        <div className="relative flex justify-center mb-6">
          <div className="w-8/12 sm:w-4/12 px-4">
            <img
              src={user}
              alt="..."
              className="shadow rounded-full w-[120px] h-[120px] sm:w-[300px] sm:h-[300px] align-middle border-none"
            />
            <Input
              className="absolute sm:rounded-full rounded-full align-middle sm:h-[300px] h-[120px] sm:top-1 top-1 sm:w-[300px] w-[120px] cursor-pointer opacity-0"
              type="file"
              name="select photo"
              onChange={formik.handleChange}
            />
          </div>
        </div>

        <label htmlFor="Full_Name" className="flex text-black text-[18px] relative w-full mb-3 ">
          <Input
            required
            type="text"
            id="fullName"
            name="fullName"
            placeholder="Full Name (Surname First)"
            autoComplete="on"
            className="block py-2.5 px-2 w-full text-gray-900 sm:text-[18px] text-[14px] rounded-md h-[54px] border-2 border-green-600"
            onChange={formik.handleChange}
          />
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 ">
            <p className="mt-2 mb-2 mr-0 ml-0 text-gray-200">{<ZuEdit />}</p>
          </div>
        </label>
        <label htmlFor="Email address" className="flex text-black text-[18px] relative w-full mb-3">
          <Input
            required
            type="text"
            id="email"
            name="email"
            placeholder="Email address"
            autoComplete="on"
            onChange={formik.handleChange}
            className="block py-2.5 px-2 w-full text-gray-900 sm:text-[18px] text-[14px] rounded-md h-[54px] border-2 border-green-600"
          />
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 ">
            <p className="mt-2 mb-2 mr-0 ml-0 text-gray-200">{<ZuEdit />}</p>
          </div>
        </label>
        <label htmlFor="Profession" className="flex text-black text-[18px] relative w-full mb-3 ">
          <Input
            required
            type="text"
            id="profession"
            name="profession"
            placeholder="Profession"
            autoComplete="on"
            onChange={formik.handleChange}
            className="block py-2.5 px-2 w-full text-gray-900 sm:text-[18px] text-[14px] rounded-md h-[54px] border-2 border-green-600"
          />
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 ">
            <p className="mt-2 mb-2 mr-0 ml-0 text-gray-200">{<ZuEdit />}</p>
          </div>
        </label>
        <label htmlFor="Language" className="flex text-black text-[18px] relative w-full mb-3 ">
          <Input
            required
            type="text"
            id="language"
            name="language"
            placeholder="Language"
            autoComplete="on"
            onChange={formik.handleChange}
            className="block py-2.5 px-2 w-full text-gray-900 sm:text-[18px] text-[14px] rounded-md h-[54px] border-2 border-green-600"
          />
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 ">
            <p className="mt-2 mb-2 mr-0 ml-0 text-gray-200">{<ZuEdit />}</p>
          </div>
        </label>
        <label htmlFor="Phone Number" className="flex text-black text-[18px] relative w-full mb-3 ">
          <Input
            required
            type="text"
            id="phoneNumber"
            name="phoneNumber"
            placeholder="Phone Number"
            autoComplete="on"
            onChange={formik.handleChange}
            className="block py-2.5 px-2 w-full text-gray-900 sm:text-[18px] text-[14px] rounded-md h-[54px] border-2 border-green-600"
          />
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 ">
            <p className="mt-2 mb-2 mr-0 ml-0 text-gray-200">{<ZuEdit />}</p>
          </div>
        </label>
        <label htmlFor="Address" className="flex text-black text-[18px] relative w-full mb-3 ">
          <Input
            required
            type="text"
            id="address"
            name="address"
            placeholder="Address"
            autoComplete="on"
            onChange={formik.handleChange}
            className="block py-2.5 px-2 w-full text-gray-900 sm:text-[18px] text-[14px] rounded-md h-[54px] border-2 border-green-600"
          />
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 ">
            <p className="mt-2 mb-2 mr-0 ml-0 text-gray-200">{<ZuEdit />}</p>
          </div>
        </label>
        <label htmlFor="City" className="flex text-black text-[18px] relative w-full mb-3 ">
          <Input
            required
            type="text"
            id="city"
            name="city"
            placeholder="City"
            autoComplete="on"
            onChange={formik.handleChange}
            className="block py-2.5 px-2 w-full text-gray-900 sm:text-[18px] text-[14px] rounded-md h-[54px] border-2 border-green-600"
          />
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 ">
            <p className="mt-2 mb-2 mr-0 ml-0 text-gray-200">{<ZuEdit />}</p>
          </div>
        </label>
        <label htmlFor="Zip" className="flex text-black text-[18px] relative w-full mb-3 ">
          <Input
            required
            type="text"
            id="zip"
            name="zip"
            placeholder="Zip"
            autoComplete="on"
            onChange={formik.handleChange}
            className="block py-2.5 px-2 w-full text-gray-900 sm:text-[18px] text-[14px] rounded-md h-[54px] border-2 border-green-600"
          />
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 ">
            <p className="mt-2 mb-2 mr-0 ml-0 text-gray-200">{<ZuEdit />}</p>
          </div>
        </label>
        <label htmlFor="State" className="flex text-black text-[18px] relative w-full mb-3 ">
          <Input
            required
            type="text"
            id="state"
            name="state"
            placeholder="State"
            autoComplete="on"
            onChange={formik.handleChange}
            className="block py-2.5 px-2 w-full text-gray-900 sm:text-[18px] text-[14px] rounded-md h-[54px] border-2 border-green-600"
          />
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 ">
            <p className="mt-2 mb-2 mr-0 ml-0 text-gray-200">{<ZuEdit />}</p>
          </div>
        </label>
        <label htmlFor="Country" className="flex text-black text-[18px] relative w-full mb-3 ">
          <Input
            required
            type="text"
            id="country"
            name="country"
            placeholder="Country"
            autoComplete="on"
            onChange={formik.handleChange}
            className="block py-2.5 px-2 w-full text-gray-900 sm:text-[18px] text-[14px] rounded-md h-[54px] border-2 border-green-600"
          />
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 ">
            <p className="mt-2 mb-2 mr-0 ml-0 text-gray-200">{<ZuEdit />}</p>
          </div>
        </label>
        <label
          htmlFor="Date of Birth"
          className="flex text-black text-[18px] relative w-full mb-3 "
        >
          <Input
            required
            type="text"
            id="dateOfBirth"
            name="dateOfBirth"
            placeholder="Date of Birth"
            autoComplete="on"
            onChange={formik.handleChange}
            className="block py-2.5 px-2 w-full text-gray-900 sm:text-[18px] text-[14px] rounded-md h-[54px] border-2 border-green-600"
          />
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 ">
            <p className="mt-2 mb-2 mr-0 ml-0 text-gray-200">{<ZuEdit />}</p>
          </div>
        </label>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%"
          }}
        >
          <Button
            size="sm"
            variant="outline"
            className="mt-5 align-center"
            style={{ marginRight: "50px" }}
          >
            Cancel
          </Button>
          <Button size="sm" type="submit" className="mt-5 align-center bg-green">
            {loading ? <Loader /> : "Change"}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default ProfileView;
