import { configureStore } from "@reduxjs/toolkit";
import reducer from "redux/slices";
import { setupListeners } from "@reduxjs/toolkit/query";
import { meetingApi } from "services/meetings.service";

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(meetingApi.middleware),
  devTools: true
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
