import { Dashboard } from "components";
import ScheduleView from "./ScheduleView";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { ScheduleMeetingValues } from "types";
import { useCreateMeetingMutation } from "services/meetings.service";

export const ScheduleContainer = () => {
  const [loading, setLoading] = useState(false);
  const [createMeeting, result] = useCreateMeetingMutation();

  useEffect(() => {
    toast.success(result.data?.MESSAGE);
    toast.error(result.isError && "Something Went Wrong");
    setLoading(result.isLoading);
  }, [result]);

  const create = async (event: ScheduleMeetingValues) => {
    await createMeeting(event);
  };

  return (
    <Dashboard>
      <ScheduleView create={create} loading={loading} />
    </Dashboard>
  );
};
