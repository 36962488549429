export const meetingsList = [
  {
    id: 1,
    title: "Design Daily Meeting",
    startTime: "10:00",
    endTime: "11:00",
    meetingStatus: "has started"
  },
  {
    id: 2,
    title: "Developers Daily Meeting",
    startTime: "10:00",
    endTime: "11:00",
    meetingStatus: "Starts in 26 hours"
  },
  {
    id: 3,
    title: "Design Daily Meeting",
    startTime: "10:00",
    endTime: "11:00",
    meetingStatus: "Starts in 26 hours"
  },
  {
    id: 4,
    title: "Developers Daily Meeting",
    startTime: "10:00",
    endTime: "11:00",
    meetingStatus: "Starts in 26 hours"
  },
  {
    id: 5,
    title: "Design Daily Meeting",
    startTime: "10:00",
    endTime: "11:00",
    meetingStatus: "Starts in 26 hours"
  },
  {
    id: 6,
    title: "Design Daily Meeting",
    startTime: "10:00",
    endTime: "11:00",
    meetingStatus: "Starts in 26 hours"
  }
];
