import { useState, useEffect } from "react";
import { Modal } from "components/modules/modals";
import randomString from "random-string";
// import { MeetingService } from "services";
import { toast } from "react-toastify";
import { meetingActions } from "utils";
import env from "configs";
import { JoinMeetingForm, MeetingLink, NewMeetingForm } from "components/modules";
import { CenterLoader } from "components/widgets";
import { meetingModalEnum } from "utils/enums";
import { ZUicons } from "assets";
import { Link } from "react-router-dom";
import { SCHEDULE } from "routes/CONSTANTS";
import { useCreateMeetingMutation } from "services/meetings.service";

type ModalId = string | null;
const Actions = () => {
  const [openModal, setOpenModal] = useState<ModalId | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [createMeeting, result] = useCreateMeetingMutation();

  useEffect(() => {
    setIsLoading(false);
    if (result.data?.STATUS === "SUCCESS") {
      window.open(`${env.MEETING_BASE_URL}/${result.originalArgs?.meetingId}`);
    } else if (result.isError) {
      toast.error("Something Went Wrong");
    }
  }, [result]);

  const handleCreateNewMeeting = async (): Promise<void> => {
    setIsLoading(true);
    const meetingId = randomString({ length: 10 }).toLowerCase();
    const date = new Date().toISOString();
    const event = {
      meetingId,
      title: "Instant Meeting",
      description: "Instant Meeting",
      date,
      start: date,
      end: date,
      meetingType: "instant",
      recurring: false,
      mute: false,
      camera: false,
      waiting: false,
      share: false,
      record: false,
      guests: []
    };
    await createMeeting(event);
  };
  return (
    <>
      <div className="grid grid-cols-2 gap-4 text-white">
        {meetingActions.map((action) => (
          <div
            key={action.id}
            onClick={
              action.id === meetingModalEnum.INSTANT
                ? async () => await handleCreateNewMeeting()
                : () => setOpenModal(action.id)
            }
            className={`rounded-2xl cursor-pointer px-4 py-8 flex flex-col w-full justify-between ${action.width} h-[194px] ${action.colSpan} ${action.backgroundColor}`}
          >
            <div className={`${action.buttonSyle}`}>
              <img
                src={action.icon}
                className={`${action.iconHeight} ${action.iconWidth} object-contain`}
              />
            </div>
            <div>
              {/* <p className="text-lg font-semibold tracking-wide">{action.task}</p> */}
              <p className="text-[14px] font-bold">{action.description}</p>
            </div>
          </div>
        ))}
        {isLoading && <CenterLoader />}
        {openModal === meetingModalEnum.INSTANT && (
          <Modal
            content={<NewMeetingForm />}
            title="Schedule a Meeting"
            setOpenModal={() => setOpenModal(null)}
          />
        )}
        {openModal === meetingModalEnum.JOIN && (
          <Modal
            content={<JoinMeetingForm />}
            title="Join Meeting"
            setOpenModal={() => setOpenModal(null)}
          />
        )}

        {openModal === meetingModalEnum.LINK && (
          <Modal
            content={<MeetingLink />}
            title="Get a meeting Link"
            setOpenModal={() => setOpenModal(null)}
          />
        )}
        {/* {openModal === meetingModalEnum.LATER && (
        <Modal
          content={<PlanMeetingForm />}
          title="Schedule for Later"
          setOpenModal={() => setOpenModal(null)}
        />
      )} */}
      </div>
      <Link
        to={SCHEDULE}
        className={` mt-4 text-white rounded-2xl cursor-pointer px-4 py-8 flex flex-col w-full justify-between h-[194px] col-span-2 bg-[#005C39]`}
      >
        <div
          className={`w-16 h-16 rounded-2xl border-[0.5px] border-[#5c9680] flex items-center justify-center bg-gradient-to-r from-[#659d87] to-[#217052]`}
        >
          <img src={ZUicons.calendarplus} className={`h-[27.67px] w-[26.67px] object-contain`} />
        </div>
        <div>
          <p className="text-[14px] font-bold">Schedule a meeting</p>
        </div>
      </Link>
    </>
  );
};

export default Actions;
