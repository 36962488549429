import { Link } from "react-router-dom";
import { DASHBOARD } from "routes/CONSTANTS";
import { Field, FieldArray, Form, Formik } from "formik";
import { ZuArrowLeft, ZuClose, Button, Loader } from "components";
import { user } from "assets/images";
import { useState } from "react";
import * as Yup from "yup";
import randomString from "random-string";
import { ScheduleMeetingValues } from "types";

const ScheduleView = ({ create, loading }: any) => {
  const [guestInput, setGuestInput] = useState("");

  const ScheduleSchema = Yup.object().shape({
    title: Yup.string().min(5, "Too Short!").max(50, "Too Long!").required(),
    description: Yup.string().required(),
    date: Yup.date().required(),
    start: Yup.string().required(),
    end: Yup.string().required()
  });

  const initialValues: ScheduleMeetingValues = {
    meetingId: "",
    title: "",
    description: "",
    date: "",
    start: "",
    end: "",
    meetingType: "",
    recurring: false,
    mute: false,
    camera: false,
    waiting: false,
    share: false,
    record: false,
    guests: []
  };

  const onSubmit = (values: ScheduleMeetingValues) => {
    const meetingId = randomString({ length: 10 }).toLowerCase();
    const startEvent: Date = new Date(`${String(values.date) + "T" + String(values.start)}`);
    const endEvent: Date = new Date(`${String(values.date) + "T" + String(values.end)}`);
    const start = startEvent.toISOString();
    const end = endEvent.toISOString();
    const event = {
      meetingId,
      title: values.title,
      description: values.description,
      date: values.date,
      start,
      end,
      meetingType: "future",
      recurring: values.recurring,
      mute: values.mute,
      camera: values.camera,
      waiting: values.waiting,
      share: values.share,
      record: values.record,
      guests: values.guests
    };
    void create(event);
  };

  return (
    <div className="flex gap-20 mb-16">
      <div className="w-full">
        <div className="flex items-center gap-6 mb-4">
          <Link className="bg-white rounded-full p-2" to={DASHBOARD}>
            {<ZuArrowLeft />}
          </Link>
          <h5 className=" font-medium text-[#5F5F5F]">Schedule Meeting</h5>
        </div>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { resetForm }) => {
            onSubmit(values);
            resetForm();
          }}
          enableReinitialize
          validationSchema={ScheduleSchema}
        >
          {({ errors, touched }) => (
            <div className="flex w-full gap-14">
              <div className="w-[100%]">
                <Form className="flex flex-col py-4">
                  <label htmlFor="title" className="text-black text-[16px]">
                    Title
                  </label>
                  <Field
                    type="text"
                    id="title"
                    name="title"
                    placeholder="Mental Health Care Day"
                    className="placeholder:text-[.85rem] text-[0.9rem] mt-1 mb-4 p-3 border border-gray-100 rounded-md w-full"
                  />
                  {errors.title && touched.title ? (
                    <p className="mb-4 text-[0.8rem] text-alerts-error-color">{errors.title}</p>
                  ) : null}
                  <label htmlFor="description" className="text-black text-[16px]">
                    Description
                  </label>
                  <Field
                    as="textarea"
                    rows={5}
                    cols={50}
                    id="description"
                    name="description"
                    placeholder="Mental Health Care day is a day set aside in a year to preach about the importance of our mental health"
                    className="placeholder:text-[.85rem] text-[0.9rem] p-3 mt-1 mb-4 border border-gray-100 rounded-md w-full"
                  ></Field>
                  {errors.description && touched.description ? (
                    <p className="mb-4 text-[0.8rem] text-alerts-error-color">
                      {errors.description}
                    </p>
                  ) : null}
                  <label htmlFor="date" className="text-black text-[16px]">
                    Date
                  </label>
                  <Field
                    id="date"
                    name="date"
                    type="date"
                    className="placeholder:text-[.85rem] text-[0.9rem] p-3 mt-1 mb-4 mr-[20px] border border-gray-100 rounded-md w-full"
                  />
                  {errors.date && touched.date ? (
                    <p className="mb-4 text-[0.8rem] text-alerts-error-color">{errors.date}</p>
                  ) : null}
                  <div className="flex mt-2 mb-4 gap-8">
                    <div className="w-full">
                      Start Time
                      <Field
                        type="time"
                        name="start"
                        className="placeholder:text-[.85rem] text-[0.9rem] p-3 mt-2 border border-gray-100 rounded-md w-full"
                      />
                      {errors.start && touched.start ? (
                        <p className="mb-4 text-[0.8rem] text-alerts-error-color">
                          Start time is required
                        </p>
                      ) : null}
                    </div>
                    <div className="w-full">
                      End Time
                      <Field
                        type="time"
                        name="end"
                        className="placeholder:text-[.85rem] text-[0.9rem] p-3 mt-2 border border-gray-100 rounded-md w-full"
                      />
                      {errors.end && touched.end ? (
                        <p className="mb-4 text-[0.8rem] text-alerts-error-color">
                          End time is required
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="bg-amber-400/30 p-4 mb-4 rounded-md">
                    <p className="text-base text-center">
                      Your Basic Plan has 60-minute time limit on meetings. Upgrade now to enjoy
                      unlimited meetings.
                      <span className="text-green font-bold ml-1 cursor-pointer">Upgrade Now!</span>
                    </p>
                  </div>
                  {/* advanced settings MOBILE */}
                  <div className="flex flex-col md:hidden gap-4 w-[100%] h-full p-6 bg-white rounded-md">
                    <p className=" font-semibold text-[1.5rem]">Advanced Settings</p>
                    <div className="text-[0.9rem]">
                      <Field className="checkbox" type="checkbox" id="recurring" name="recurring" />
                      <label htmlFor="recurring">Recurring Meeting</label>
                      <br></br>
                      <Field className="checkbox" type="checkbox" id="mute" name="mute" />
                      <label htmlFor="mute">Mute participants upon entry</label>
                      <br></br>
                      <Field className="checkbox" type="checkbox" id="camera" name="camera" />
                      <label htmlFor="camera">Keep participants camera off upon entry</label>
                      <br></br>
                      <Field className="checkbox" type="checkbox" id="waiting" name="waiting" />
                      <label htmlFor="waiting">Keep participants in waiting room </label>
                      <br></br>
                      <Field className="checkbox" type="checkbox" id="share" name="share" />
                      <label htmlFor="share">Only host can share screen</label>
                      <br></br>
                      <Field className="checkbox" type="checkbox" id="record" name="record" />
                      <label htmlFor="record">Automatically record meeting to cloud storage </label>
                      <br></br>
                    </div>
                  </div>
                  <div className="flex flex-col mt-2 mb-4">
                    <label htmlFor="guests" className="text-black text-[16px]">
                      Add Guests
                    </label>
                    <div className="relative flex items-center gap-0 mt-2 w-full">
                      <FieldArray name="guests">
                        {(fieldArrayProps) => {
                          const { push, remove, form } = fieldArrayProps;
                          const { values } = form;
                          const { guests } = values;
                          return (
                            <div className="w-full">
                              <div className="flex mb-4">
                                <input
                                  id="guests"
                                  type="email"
                                  name="guests"
                                  onChange={(e) => setGuestInput(e.target.value)}
                                  value={guestInput}
                                  placeholder="Enter guest email address"
                                  className=" placeholder:text-[.85rem] text-[0.9rem] p-3 ab w-full border border-gray-100 rounded-l-md"
                                />
                                <button
                                  disabled={
                                    !guestInput.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)
                                  }
                                  type="button"
                                  onClick={() => {
                                    if (
                                      guestInput.match(
                                        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
                                      )
                                    ) {
                                      push(guestInput);
                                    }
                                    setGuestInput("");
                                  }}
                                  className="bg-green disabled:bg-slate-400/40 text-white py-3 rounded-r-md min-w-[100px] h-full disabled:border-slate-400/0 border-green border-[1px]"
                                >
                                  Add Guest
                                </button>
                              </div>
                              <div className="flex gap-2 flex-wrap">
                                {guests.map((guests: string, index: number) => (
                                  <div key={index}>
                                    <div
                                      id={`guests[${index}]`}
                                      className="bg-white p-2 w-fit rounded-full flex items-center border-gray-100 border-[1px]"
                                    >
                                      <img
                                        className="w-7 flex-none mr-2"
                                        src={user}
                                        alt="profile"
                                      />
                                      <p className="text-[0.8rem]">{guests}</p>
                                      <ZuClose
                                        onClick={() => remove(index)}
                                        color="red"
                                        size={10}
                                        className="cursor-pointer flex-1 w-8"
                                      />
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          );
                        }}
                      </FieldArray>
                    </div>
                  </div>
                  <div className="flex pt-8 gap-8">
                    {/* <button type="submit" className="bg-green text-white py-2 px-14 rounded-md">
                      Save
                    </button> */}
                    <Button
                      size="lg"
                      type="submit"
                      className="bg-green text-white py-2 px-20 rounded-md"
                    >
                      {loading ? <Loader /> : "Save"}
                    </Button>
                    <Link
                      to={DASHBOARD}
                      className="flex items-center bg-none text-red-500 border-2 border-red-500 rounded-md px-14"
                    >
                      Cancel
                    </Link>
                  </div>
                </Form>
              </div>
              <div className="flex-col hidden md:flex gap-4 w-[40%] h-full p-6 bg-white rounded-md">
                <p className=" font-semibold text-[1.5rem]">Advanced Settings</p>
                <div className="text-[0.9rem]">
                  <Field className="checkbox" type="checkbox" id="recurring" name="recurring" />
                  <label htmlFor="recurring">Recurring Meeting</label>
                  <br></br>
                  <Field className="checkbox" type="checkbox" id="mute" name="mute" />
                  <label htmlFor="mute">Mute participants upon entry</label>
                  <br></br>
                  <Field className="checkbox" type="checkbox" id="camera" name="camera" />
                  <label htmlFor="camera">Keep participants camera off upon entry</label>
                  <br></br>
                  <Field className="checkbox" type="checkbox" id="waiting" name="waiting" />
                  <label htmlFor="waiting">Keep participants in waiting room </label>
                  <br></br>
                  <Field className="checkbox" type="checkbox" id="share" name="share" />
                  <label htmlFor="share">Only host can share screen</label>
                  <br></br>
                  <Field className="checkbox" type="checkbox" id="record" name="record" />
                  <label htmlFor="record">Automatically record meeting to cloud storage </label>
                  <br></br>
                </div>
              </div>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ScheduleView;
