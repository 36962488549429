import { CREATE_NEW_MEETING, GET_ALL_MEETINGS, NEW_MEETING_TAG } from "./CONSTANTS";
// import fetchInstance from "./utils/FetchInterceptor";
import { api } from "./api";
import { meetingResponse, ScheduleMeetingValues } from "types";

export const meetingApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllMeetings: builder.query<meetingResponse, string>({
      query: (email) => `${GET_ALL_MEETINGS}/${email}`,
      providesTags: [NEW_MEETING_TAG]
    }),
    createMeeting: builder.mutation<meetingResponse, ScheduleMeetingValues>({
      query: (event) => ({
        url: `${CREATE_NEW_MEETING}`,
        method: "POST",
        body: event
      }),
      invalidatesTags: [NEW_MEETING_TAG]
    })
  })
});

export const { useGetAllMeetingsQuery, useCreateMeetingMutation } = meetingApi;

// export const createMeeting = async (meetingParams: any) => {
//   try {
//     const response = await fetchInstance({
//       url: CREATE_NEW_MEETING,
//       method: "post",
//       data: meetingParams
//     });
//     return response;
//   } catch (err) {
//     return err;
//   }
// };

// export const fetchMeetings = async (userId: any) => {
//   try {
//     const response = await fetchInstance({
//       url: GET_ALL_MEETINGS,
//       method: "get",
//       data: userId
//     });
//     return response;
//   } catch (err) {
//     return err;
//   }
// };
