import ProfileView from "./ProfileView";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAppSelector } from "hooks";
import isEmptyObject from "utils/isEmptyObject";
import { ZUMARIDI_USER_DATA } from "services/CONSTANTS";

function ProfileContainer() {
  const { isLoading } = useAppSelector((state) => state.auth);
  const data = JSON.parse(localStorage.getItem(ZUMARIDI_USER_DATA) as string);

  const formik = useFormik({
    initialValues: {
      fullName: isEmptyObject(data?.user) ? "" : data.user.name,
      email: isEmptyObject(data?.user) ? "" : data.user.email,
      profession: isEmptyObject(data?.user) ? "" : data.user.profession,
      language: isEmptyObject(data?.user) ? "" : data.user.language,
      phoneNumber: isEmptyObject(data?.user) ? "" : data.user.phoneNumber,
      address: isEmptyObject(data?.user) ? "" : data.user.address,
      city: isEmptyObject(data?.user) ? "" : data.user.city,
      zip: isEmptyObject(data?.user) ? "" : data.user.zip,
      state: isEmptyObject(data?.user) ? "" : data.user.state,
      country: isEmptyObject(data?.user) ? "" : data.user.country,
      dateOfBirth: isEmptyObject(data?.user) ? "" : data.user.dateOfBirth
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required("Full name is required"),
      email: Yup.string().email("Email is required"),
      profession: Yup.string().required("Profession is required"),
      language: Yup.string().required("Language is required"),
      phoneNumber: Yup.string().required("Phone number is required"),
      address: Yup.string().required("Address is required"),
      city: Yup.string(),
      zip: Yup.string(),
      state: Yup.string(),
      country: Yup.string(),
      dateOfBirth: Yup.string()
    }),
    onSubmit: () => {}
  });
  return <ProfileView loading={isLoading} formik={formik} />;
}

export default ProfileContainer;
